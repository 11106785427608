import Tabbar from "@/components/tabbar";
import { getCookie, setCookie } from "@/utils/auth";
import { gongGao } from "@/api";
export default {
  data() {
    return {
      images: [require("../../assets/banner01.jpg"), require("../../assets/banner06.jpg"), require("../../assets/banner03.png"), require("../../assets/banner05.jpg"), require("../../assets/banner04.png"), require("../../assets/banner02.jpg")],
      offset: {
        x: 0,
        y: 0
      },
      notice: "",
      dialogText: "",
      downAppUrl: "",
      showCenter: false,
      showDownBtn: false
    };
  },
  mounted() {
    gongGao().then(res => {
      this.notice = res.gundong;
      this.downAppUrl = res.appUrl;
      this.dialogText = res.data;
      //   console.log(this.dialogText)
    });
    if (!getCookie('tanchuang')) {
      this.showCenter = true;
    }
    if (process.env.VUE_APP_TYPE != 'app') {
      this.showDownBtn = true;
    }
  },
  methods: {
    closeDialog() {
      var inFifteenMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);
      setCookie('tanchuang', '1', {
        expires: inFifteenMinutes
      });
      this.showCenter = false;
    },
    downApp() {
      window.open(this.downAppUrl);
    }
  },
  components: {
    Tabbar
  }
};